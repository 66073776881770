import { useMutation, useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { useForm } from 'react-hook-form';
import { createFaq } from '../../../../api/Settings/FAQ/FAQ';
import useTranslate from '../../../../hooks/useTranslate';
import Button from '../../../Common/Button';
import ErrorMessage from '../../../Common/ErrorMessage';
import Input from '../../../Common/Input';
import TextArea from '../../../Common/TextArea';

const CreateFAQForm = ({ cancelForm, FAQType }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ mode: 'all' });
  const t = useTranslate();
  const queryClient = useQueryClient();

  const {
    mutateAsync: createFAQ,
    isLoading,
    isError,
  } = useMutation(createFaq, {
    onSuccess: () => {
      cancelForm();
      reset();
      queryClient.invalidateQueries(['Faqs']);
    },
  });

  const submitHandler = (data) => {
    createFAQ({
      question: data.question,
      answer: data.answer,
      type: 'Admin created',
      user_type: FAQType,
      administrator_id: 1,
    });
  };

  return (
    <form className="flex flex-col w-full my-2">
      <Input
        register={register}
        placeholder={`${t('question')}...`}
        type="text"
        name={'question'}
        required={t('thisFieldIsRequired')}
        isError={errors?.question?.message}
        noLabel
      />
      <ErrorMessage>{errors?.question?.message}</ErrorMessage>
      <TextArea
        register={register}
        placeholder={`${t('answer')}...`}
        type="text"
        name="answer"
        required={t('thisFieldIsRequired')}
        isError={errors?.answer?.message}
      />
      <ErrorMessage>{errors?.answer?.message}</ErrorMessage>
      <div className="flex gap-3 mt-3 ml-auto">
        <Button onClick={cancelForm}>{t('cancel')}</Button>
        <Button onClick={handleSubmit(submitHandler)}>{t('save')}</Button>
      </div>
    </form>
  );
};

export default CreateFAQForm;
